<template>
  <div class="w-full h-screen overflow-y-auto" id="layout-content-area">
    <LayoutNavbar
      :nav-bar-items="navBarItems"
      :compacted="compacted"
      @toggle-compact="emits('toggleCompact')"
      @toggle-sidebar-opened="emits('toggleSidebarOpened')"
    />
    <div
      class="flex flex-col flex-auto"
      @click="emits('toggleSidebarOpened', false)"
    >
      <div
        class="border-surface rounded-border bg-transparent flex-auto"
      >
        <slot/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { navBarItems, compacted } = defineProps({
  navBarItems: {
    type: Array,
    default: () => [],
  },
  compacted: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["toggleCompact", "toggleSidebarOpened"]);
</script>

<style scoped>
/* Ensure proper scrolling on iOS devices */
#layout-content-area {
  -webkit-overflow-scrolling: touch;
}
</style>
